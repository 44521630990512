*{
	font-family: $font-stack;
	color: #525251;
	text-decoration: none !important;
	outline: none !important;
}

@font-face {
    font-family: 'BellotaBold';
    src: url('../fonts/BellotaBold.eot');
    src: url('../fonts/BellotaBold.eot') format('embedded-opentype'),
         url('../fonts/BellotaBold.woff2') format('woff2'),
         url('../fonts/BellotaBold.woff') format('woff'),
         url('../fonts/BellotaBold.ttf') format('truetype'),
         url('../fonts/BellotaBold.svg#BellotaBold') format('svg');
}

*{
   -webkit-appearance: none !important;
   -moz-appearance:    none !important;
   appearance:         none !important;
}

.SubDate{
	
    font-family: 'BellotaBold';
    color: #1F0B76;
    margin-top: 20px;
    font-sizE: 1.4em;

}

body{
	overflow-y: scroll;
	overflow-x: hidden;
	background-color: #2f98f0;
}

.flex{
	display: flex;
}

.eventTitle{
	    padding-top: 4px;
}

.toggleBtn{
	cursor: pointer;
}

.today{
	    color: #2f98f0 !important;
}
.calendarSectionTitle{
	color: #5a5962;
	text-transform: uppercase;
}
.calendar-widget-table tbody .hasEvent {
    background-image: url(../images/dot.png);
    background-repeat: no-repeat;
    background-position: 90% 10%;
    background-size: 5px;
}
.calendar-widget-table tbody .calendar-header td {
    background-color: transparent;
    color: #5a5962;
    font-weight: bold;
    text-transform: uppercase;
    border-color: transparent;
}
.calendar-widget-table table td, .calendar-widget table th{
	border: none !important;
}
.calendar-widget-table tbody .out-of-month {
    background-color: transparent;
    color: #999;
}
.calendar-widget-table tbody .calendar-day:hover, .calendar-widget tbody .show-week:hover, .calendar-widget tbody .selected {
    background-color: #2f98f0;
    cursor: pointer;
    color: #ffffff !important;
}


.CalendarFilter{
	padding: 10px;
}

/* .CalendarFilter{
	span{
		display: inline-block;
		padding: 5px 0px;
		margin: 0px 5px;
		cursor: pointer;
	}
} */
.CalendarEvent #allEvents ul li,
.Calendar #allEvents ul li{
	margin-left: 0px;
    margin-right: 0px;
/* 	display: none; */
}
/* .CalendarEvent.All #allEvents ul li,
.Calendar.All #allEvents ul li{
	display: block;
} */
.CalendarFilter span{
	cursor: pointer;
	display: inline-block;
	padding: 5px;
	border-bottom: 2px solid transparent;
	margin-bottom: 10px;
}
.CalendarFilter span.active{
	border-bottom: 2px solid $themeColor !important;
	color: $themeColor !important;
}
.noEvents{
	display: none;
	padding: 20px 10px;
	color: #666;
	@include border-radius(3px);
    background-color: #eee;
}

#allEvents ul{
	max-width: 500px;
	margin: 0px !important;
	padding: 0px !important;
}
#allEvents ul li,
#HomepageEvents ul li{
@include border-radius(3px);
	*{
		color: #fff !important;
	}
	margin-bottom: 10px;
	padding: 10px;
	background-color: rgba(47, 152, 240, 1);
	float: left;
    width: 100%;
    clear: both;
    @include transition(background 0.35s);
    display: block;
    &:hover{
		background-color: rgba(47, 152, 240, 0.9)!important;
    }
}
.calendar-widget-table{
	width: 100%;
}
.calendar-widget-table tbody td {
    background-color: transparent;
    padding: 20px 0px !important;
    @include transition($t1);
    text-align: center !important;
    width: 13%;
    font-size: 1em;
}

.calendar-widget-table thead {
	padding-bottom: 68px;
    display: block;
	width: 100%;
}

.calendar-widget-table .next,
.calendar-widget-table .prev{
    position: absolute;
    *{
    color: #fff !important;
    }
  
    font-weight: lighter;
    font-size: 1.3em;
    line-height: 1.4em;
    width: 40px;
    height: 40px;
    background-color: #303c68;
    text-align: center;
    border-radius: 50%;
    @include transition($t1);
}
.calendar-widget-table .prev{
	right: 75px;
	color: #fff !important;
}
.calendar-widget-table .next{
	right: 0px;
	color: #fff !important;
	padding-left: 5px;
}
.calendar-widget-table .next,
.calendar-widget-table .prev{
	&:hover{
		background-color: #2f98f0 !important;
		color: #fff !important;
	}
}

.calendar-widget {
    position: relative;
}

.calLeft{
	padding-right: 14%;
	border-right: 2px solid #5a5962;
}
.calRight{
	padding-left: 14%;
}
.show-month {
    font-size: 1.8em;
 	position: absolute;
    left: 0px;
    top: 0px;
    color: #5a5962 !important;
    font-family: 'BellotaBold';
    color: #1f0b79;
}
.toggleContent{
	display: none;
	font-size: 0.8em;
	*{
		font-size: 1em;
		color: #fff !important;
	}
	.url{
		
	}
}
a.important{
	color: #c00;
	font-weight: bold;
}

#tickerContainer{
	clear: both;
	position: relative;
	padding: 0px;
	width: 100%;
	overflow: hidden;
	max-height: 52px;
}

.bgwhite{
	background: #fff !important;
}

.tickertape{
	float: left;
	position: relative;
	overflow: hidden;
}
 
.tickertape section,
.tickertape span{
}

.tickertape a{
	color: #150068;

}

.tickertape section{
	white-space: nowrap;
	display:inline-block;
	float: left;
	padding: 15px 0;
	position: relative;
	text-align: center;
	color: #150068;
	letter-spacing: 1px;
	font-size: 18px;
}

.ticker{
	margin-top: 70px;
	position: relative;
	clear: both;
	float: left;
	top: auto;
	max-height: 52px;
	width: 100%;
	background-color: #feec52;
}

#LatestNews{
	display: none;
	position: absolute;
    left: 0px;
    padding-left: 10px;
    background: #C5173F;
    height: 46px;
    color: #fff;
    z-index: 2;
    line-height: 45px;
    font-size: 1.6em;
    text-transform: uppercase;
    padding-right: 20px;
}


.GradBG{
	position: absolute;
	top: 0px;
	width: 100%;
	height: 100%;
    opacity: 0.7;
	left: 0px;
	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,0.65+100 */
	background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.65) 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 ); /* IE6-9 */
} 


.topBar{    
	background-color: rgb(47,152,240);
	border-bottom: 10px solid #fff;
	position: fixed;
	top: 0px;
	width: 100%;
	right: 0px;
	z-index: 8000;
	@include box-shadow(0px 0px 30px 0px rgba(0,0,0,0.1));
}
.HomePage{
	.topBar{
		background-color: rgba(47,152,240,0.85);
	}
}


#MenuContainer{ 
	margin-top: 100px;
	position: relative;
    float: right;
    right: 146px;
    clear: both;
    text-align: right;
    z-index: 8002;
    width: 100%;
}

#logo2{
	position: absolute;
	right: 20px;
	top: 50%;
	@include transform(translateY(-50%));
}
#Logo{
	position: absolute;
	left: 20px;
	top: 50%;
	@include transform(translateY(-50%));
	z-index: 8001;
	.LogoImage,
	.LogoText{
		display: inline-block;
		vertical-align: middle;
	}
	a{
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
	}
	.LogoText{
		span{
			display: block;
		}
		span:first-of-type{
			color: #fdfc33;
    		font-size: 1.7em;
    		font-family: 'BellotaBold';
		}
		span:last-of-type{
			color: #1f0b79;
    		font-size: 1.2em;
		}
	}
}


/* Wobble Bottom */
@-webkit-keyframes hvr-wobble-bottom {
  16.65% {
    -webkit-transform: skew(-12deg);
    transform: skew(-12deg);
  }

  33.3% {
    -webkit-transform: skew(10deg);
    transform: skew(10deg);
  }

  49.95% {
    -webkit-transform: skew(-6deg);
    transform: skew(-6deg);
  }

  66.6% {
    -webkit-transform: skew(4deg);
    transform: skew(4deg);
  }

  83.25% {
    -webkit-transform: skew(-2deg);
    transform: skew(-2deg);
  }

  100% {
    -webkit-transform: skew(0);
    transform: skew(0);
  }
}

@keyframes hvr-wobble-bottom {
  16.65% {
    -webkit-transform: skew(-12deg);
    transform: skew(-12deg);
  }

  33.3% {
    -webkit-transform: skew(10deg);
    transform: skew(10deg);
  }

  49.95% {
    -webkit-transform: skew(-6deg);
    transform: skew(-6deg);
  }

  66.6% {
    -webkit-transform: skew(4deg);
    transform: skew(4deg);
  }

  83.25% {
    -webkit-transform: skew(-2deg);
    transform: skew(-2deg);
  }

  100% {
    -webkit-transform: skew(0);
    transform: skew(0);
  }
}

.hvr-wobble-bottom {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transform-origin: 0 100%;
  transform-origin: 0 100%;
}
.hvr-wobble-bottom:hover, .hvr-wobble-bottom:focus, .hvr-wobble-bottom:active {
  -webkit-animation-name: hvr-wobble-bottom;
  animation-name: hvr-wobble-bottom;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}

/* Hang */
@-webkit-keyframes hvr-hang {
  0% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }

  50% {
    -webkit-transform: translateY(4px);
    transform: translateY(4px);
  }

  100% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
}

@keyframes hvr-hang {
  0% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }

  50% {
    -webkit-transform: translateY(4px);
    transform: translateY(4px);
  }

  100% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
}

@-webkit-keyframes hvr-hang-sink {
  100% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
}

@keyframes hvr-hang-sink {
  100% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
}

.hvr-hang2 {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
}
.hvr-hang {
  -webkit-animation-name: hvr-hang-sink, hvr-hang;
  animation-name: hvr-hang-sink, hvr-hang;
  -webkit-animation-duration: .3s, 1.5s;
  animation-duration: .3s, 1.5s;
  -webkit-animation-delay: 0s, .3s;
  animation-delay: 0s, .3s;
  -webkit-animation-timing-function: ease-out, ease-in-out;
  animation-timing-function: ease-out, ease-in-out;
  -webkit-animation-iteration-count: 1, infinite;
  animation-iteration-count: 1, infinite;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-direction: normal, alternate;
  animation-direction: normal, alternate;
}


/*** Menu ***/
#Menu{
    z-index: 9999;
    /* padding-left: 200px; */
    display: inline-block;
    text-align: right;
	*{
	}
	/*
	.MenuButton{
		display: inline-block;
		position: absolute;
		z-index: 2;
		cursor: pointer;
		background-color: #73a8d9;
	    right: 0px;
	    padding: 8px 12px 7px 12px;
	    cursor: pointer;
	    opacity: 1;
	    top: 0px;
	    text-transform: uppercase;
	}
	*/
	.MenuContainer{
		position: relative;
		.LevelOne{
			display: inline-block;
			text-align: center;
			position: relative;
			a{
				padding: 14px 20px;
			    font-size: 1em;
			    line-height: 3em;
			    display: inline-block;
	   	 		line-height: 1.1em;
	   	 		@include transition($t1);
	   	 		
			}
			
		}
	}
	.LevelOne{
		.topMenuLink{
			color: #1f0b79;
		}
	}
	.active.LevelOne{
		.topMenuLink{
	 		color: #fff !important;
		}
	}

	.SubMenu{
		@include box-shadow(-2px 13px 40px 0px rgba(47, 47, 47, 0.33));
		width: 250px;
		height: 0px;
		overflow: hidden;
		display: block;
		z-index: -9999;
    	left: 50%;
    	text-align: left;
		position: absolute;    /*
		background-color: rgba(255, 255, 255, 0.82);*/
		background-color: #0082ed;
    	/*border: 3px solid #0082ed;*/
	    @include border-radius(5px);
	    margin-top: 20px;
	    padding: 6px 6px 6px 6px;
	    opacity: 0;
	    transition: transform 0.35s 0.120s, opacity 0.35s 0.120s;
	    -o-transition: transform 0.35s 0.120s, opacity 0.35s 0.120s;
	    -ms-transition: transform 0.35s 0.120s, opacity 0.35s 0.120s;
	    -moz-transition: transform 0.35s 0.120s, opacity 0.35s 0.120s;
	    -webkit-transition: transform 0.35s 0.120s, opacity 0.35s 0.120s;
	    @include transform(translateY(20px) translateX(-50%) scale(0.98));

	    a{
		    display: inline-block;
		    padding: 10px 20px !important;
		    /*font-size: 0.9em;*/
		    @include border-radius(3px);
    		font-size: 0.7em !important;
		    line-height: 1.2em !important;
		    width: 100%;
		    color: #fff !important;
			background-color: transparent;
	    	@include transition($t1);
			&:hover{
    			background-color: rgba(0,0,0, 0.1)!important;
			}
		}
	}

	.SubMenu:before {
	    content: '';
	    display: block;
	    position: absolute;
	    width: 100%;
	    height: 17px;
	    left: 0px;
	    top: -14px;
	    z-index: 2;
	}
	.SubMenu:after {
	    content: '';
	    display: block;
	    position: absolute;
	    border: 10px solid transparent;
	    top: -20px;
	    left: 50%;
	    margin-left: -10px;
	    border-bottom: 10px solid #0082ed;
	}

	.menuItem-1,
	.menuItem-2{
		.SubMenu{
			left: 0%;
			@include transform(translateY(20px) translateX(0%) scale(0.98));
			&:after {
				left: 40px !important;
			}
		}
	}
	.menuItem-Last{
		.SubMenu{
			left: auto;
			right: 0%;
			@include transform(translateY(20px) translateX(0%) scale(0.98));
			&:after {
				left: auto !important;
				right: 40px !important;
			}
		}
	}
	.active .SubMenu{
		opacity: 1 !important;
	 	@include transform(translateY(0px) translateX(-50%) scale(1) !important);
	}
	.menuItem-1.active .SubMenu,
	.menuItem-2.active .SubMenu{
		opacity: 1 !important;
	 	@include transform(translateY(0px) translateX(0%) scale(1) !important);
	 	
	}
	.menuItem-Last.active .SubMenu{
			opacity: 1 !important;
			left: auto;
			right: 0%;
			@include transform(translateY(0px) translateX(0%) scale(1) !important);	
	}


}



.topMenuLink{
	position: relative;
}
.topMenuLink:after{
	background-color: #feec64;
	right: 0px;
	content:'';
	position: absolute;
	top: 0px;
	top: 50%;
    width: 2px;
    height: 35%;
    @include transform(translateY(-50%));
}
.MenuContainer .LevelOne:last-of-type a:after{
	display: none !important;
}

.flexslider .slides li {
    position: relative;
    height: 110vh;
}
.pageBanner{
/*	background-image: url(../images/bg.jpg); */
	background-size: cover;
	background-position: center;
	height: 70vh !important;
	.flexslider .slides li {
	    position: relative;
	    height: 70vh;
	}	
}
.leftImg,
.rightImage{
	background-image: url(../images/bg.jpg);
	background-size: cover;
	background-position: center;
}
.slideBG{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	background-image: url(../images/bg.jpg);
	background-size: cover;
	background-position: center;
	background-attachment: fixed;
	-webkit-background-attachment: fixed;
}
.homeContent{
	font-size: 1.2em;
}

#down{
	cursor: pointer;
	position: absolute;
    bottom: -150px;
	left: 50%;
	@include transform(translateX(-50%));
}

/*** Scroll Bar ***/
::-webkit-scrollbar{
	width: 8px;
	height:9px;
}
::-webkit-scrollbar-button:start:decrement,
::-webkit-scrollbar-button:end:increment{
	display: block;
	height: 0;
	background-color:transparent;
}
::-webkit-scrollbar-track-piece{
	background-color: #333;
	@include border-radius(0px);
}
::-webkit-scrollbar-thumb{
	background-color: #777;
	@include border-radius(0px);
}
::-webkit-scrollbar-thumb:vertical{
}
::-webkit-scrollbar-thumb:horizontal{
}
::-webkit-scrollbar-thumb:hover{
	background-color: #777;
}

/*** Scroll Bar ***/
.TopLevel::-webkit-scrollbar{
	width: 6px;
	height:9px;
}
.TopLevel::-webkit-scrollbar-button:start:decrement,
.TopLevel::-webkit-scrollbar-button:end:increment{
	display: block;
	height: 0;
	background-color:transparent;
}
.TopLevel::-webkit-scrollbar-track-piece{
	background-color: #213b94;
	@include border-radius(0px);
}
.TopLevel::-webkit-scrollbar-thumb{
	background-color: #275abb;
	@include border-radius(0px);
}
.TopLevel::-webkit-scrollbar-thumb:vertical{
}
.TopLevel::-webkit-scrollbar-thumb:horizontal{
}
.TopLevel::-webkit-scrollbar-thumb:hover{
	background-color: #275abb;
}

.BannerText{
	position: absolute;
    bottom: 39vh;
	left: 50%;
	z-index: 700;
    font-size: 1.4em;
	@include transform(translateX(-50%));
	span{
		display: block;
    	font-family: 'BellotaBold';
		text-align: center;
	}
	span:first-of-type{
		font-size: 1.7em;
		color: #feec52;
	}
	span:last-of-type{
		font-size: 2.2em;
		color: #fff;
	}
}

.newsBottom{
	position: absolute;
    bottom: -3px;
	left: 0px;
	width: 100%;
	display: block;
	max-height: 100px;
}



#home4{
	padding: 100px 0px 0px 0px;
	background-color: #fff;
	/*min-height: 60vh;*/
}

#QuickLinks{
	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#9bc9f7+0,9bc9f7+97&0+0,0+18,0.71+83,1+95 */
	background: -moz-linear-gradient(top, rgba(155,201,247,0) 0%, rgba(155,201,247,0) 18%, rgba(155,201,247,0.71) 83%, rgba(155,201,247,1) 95%, rgba(155,201,247,1) 97%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top, rgba(155,201,247,0) 0%,rgba(155,201,247,0) 18%,rgba(155,201,247,0.71) 83%,rgba(155,201,247,1) 95%,rgba(155,201,247,1) 97%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, rgba(155,201,247,0) 0%,rgba(155,201,247,0) 18%,rgba(155,201,247,0.71) 83%,rgba(155,201,247,1) 95%,rgba(155,201,247,1) 97%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#009bc9f7', endColorstr='#9bc9f7',GradientType=0 ); /* IE6-9 */
}
.QuickLink{
	padding-bottom: 25%;
	opacity: 0;
	overflow: hidden;
	@include transform( scale(0.8));
	.QuickLinkBG{
		position: absolute;
		top: 0px;
		width: 101%;
		left: 0px;
		height: 102%;
		@include transform(scale(1));
		@include transition($t1);
		background-position: center;
		background-size: cover;
		background-image: url(../images/bg.jpg);
	}
	a{
		z-index: 1;
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0px;
		top: 0px;
	}
	.QuickLinkdarken{
		background-color: rgba(0,0,0,0.2);
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0px;
		@include transition($t1);
		top: 0px;
	}
	.QuickLinkCenter{
		position: absolute;
		top: 70%;
		left: 50%;
    	width: 90%;
		text-align: center;
		@include transition($t1);
		@include transform($center);
		.part-1{

		}
		.part-2{
    		font-family: 'BellotaBold';
			text-align: center;
			margin-bottom: 5px;
			display: block;
			width: 100%;
			color: #fff;
			font-size: 2.5em;
		}
		.part-3{

		}
	}
	&:hover{
		.QuickLinkCenter{
			top: 50% !important;
		}
		.QuickLinkdarken{
				background-color: rgba(0,0,0,0.5) !important;
		}
		.QuickLinkBG{
			@include transform(scale(1.2) !important);
		}
	}
}

#home2.ActiveIn{
	.QuickLink{
		@include transform( scale(1) !important);
		opacity: 1 !important;
	}
}


.bottomSwirl{
	position: absolute;
	top: 0px;
	width: 100%;
	@include transform(translateY(-97%));
	left: 0px;
	max-height: 100px;
}

.overlay {
    position: absolute;
    left: 0px;
    max-height: 101%;
    top: 50%;
    transform-origin: 0% 100%;
    -o-transform-origin: 0% 100%;
    -ms-transform-origin: 0% 100%;
    -moz-transform-origin: 0% 100%;
    -webkit-transform-origin: 0% 100%;
	@include transform(translateY(-50%) rotate(0deg));
  	animation: floatingOne 3s infinite alternate;
  	-webkit-animation: floatingOne 3s infinite alternate;
}

@keyframes floatingOne {
  0% {
	@include transform(translateY(-50%) rotate(0deg));
  }
  100% {
	@include transform(translateY(-50%) rotate(10deg));
  }
}


.overlay3{
	position: absolute;
	top: 50%;
	max-height: 80%;
	left: 0px;
  	animation: floatingOne 3s infinite alternate;
  	-webkit-animation: floatingOne 3s infinite alternate;
}

@keyframes floatingOne {
  0% {
	@include transform(translateY(-50%) rotate(0deg));
  }
  100% {
	@include transform(translateY(-50%) rotate(10deg));
  }
}
.pageImage,
.homeImg{
	@include border-radius(50%);
    border: 6px solid #fdfc33;
    width: 240px;
    padding-bottom: 240px;
    background-size: cover;
    background-position: center;
    display: block;
}
.homeImg{
    margin: 20px auto;
}

.pageTitle{    
	font-family: 'BellotaBold';
	color: #1f0b79;
    font-size: 3em;
    position: relative;
    margin-bottom: 40px;
    img.line{
    	position: absolute;
    	bottom: -20px;
    	left: 0px;
    }
}

.centerTitle{
	img.line{
    	position: absolute;
    	left: 50%;
    	bottom: -30px;
    	@include transform(translateX(-50%));
    }
}
#home1{
	.pageTitle{    

		color: #feec52 !important;
	}
}

.flexslider .flex-direction-nav {
    position: absolute;
    bottom: 16%;
    left: 50%;
    height: 30px;  
    z-index: 800;
    width: calc(100% - 80px);
    @include transform($center);
}

#home3{
	background-color: #9bc9f7;
	min-height: 100vh;
	overflow: hidden;
	display: flex;
}
#home4{
	.underlineBtn{
		display: inline-block;
	}
}
#newsLeft{
	background-position: center;
	background-size: cover;
	background-image: url(../images/img8.jpg);
	.grad{
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
		/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#9bc9f7+0,9bc9f7+97&0.21+0,0.71+83,1+95 */
		background: -moz-linear-gradient(left, rgba(155,201,247,0.21) 0%, rgba(155,201,247,0.71) 83%, rgba(155,201,247,1) 95%, rgba(155,201,247,1) 97%); /* FF3.6-15 */
		background: -webkit-linear-gradient(left, rgba(155,201,247,0.21) 0%,rgba(155,201,247,0.71) 83%,rgba(155,201,247,1) 95%,rgba(155,201,247,1) 97%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to right, rgba(155,201,247,0.21) 0%,rgba(155,201,247,0.71) 83%,rgba(155,201,247,1) 95%,rgba(155,201,247,1) 97%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#369bc9f7', endColorstr='#9bc9f7',GradientType=1 ); /* IE6-9 */
	}
}

#newsRight{
	padding: 150px 50px;
}
.pageoverlay,
.newsOverlay{
	position: absolute;
	right: 0px;
	top: 50%;
	@include transform(translateY(-50%));
  	animation: floatingTwo 5s infinite alternate;
  	-webkit-animation: floatingTwo 5s infinite alternate;
}
@keyframes floatingTwo {
  0% {
	@include transform(translateY(-50%) rotate(2deg));
  }
  100% {
	@include transform(translateY(-50%) rotate(-5deg));
  }
}
.pageoverlay{
	max-height: 90%;
}
/* Slider Styling */
#SliderSectionContainer{
	margin-bottom: 50px;
}
.SliderTitle {
    font-size: 1.5em;
    color: #EE363C;
    text-align: center;
    padding: 20px 0px 30px 0px;
}
.bx-controls{
	text-align: center;
}
.bx-pager-item{
	margin: 5px;
	width: 10px;
	height: 10px;
	opacity: 0.5;
	background-color: #2b2b2b;
	transition: all 0.35s;
	-o-transition: all 0.35s;
	-ms-transition: all 0.35s;
	-moz-transition: all 0.35s;
	-webkit-transition: all 0.35s;
	border-radius: 50%;
	-o-border-radius: 50%;
	-ms-border-radius: 50%;
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
	display: inline-block;
}
.bx-pager-item:hover{
	opacity: 1 !important;
}
.bx-pager-item a{
	color: transparent;
}
/* Slider Styling */

/*** Video Page ***/
.videoHeader{
	height: 120px;
	background-color: #000;
}
.fullScreenBlackout{
	display: none;
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100%;
	background-color: #000;
	height: 100%;
	z-index: 9998;
}
.videoContainer{
	padding-bottom: 25%;
	overflow: hidden;
}
.video_background{
	width: 100%;
	position: absolute;
	top: 50%;
	left: 50%;
	@include transform($center);
}

.active.video_background{
    position: fixed;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    z-index: 9999;
}
.active{
	.closeVideo{
		display: block !important;
	}
}

.videoBlackout{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	background-color: rgba(0,0,0, 0.4);
}

.closeVideo{
	display: none;
	position: fixed;
	top: 10px;
	right: 10px;
	z-index: 9999;
	cursor: pointer;
	color: #fff;
	font-size: 1.8em;
	i{color: #fff;}
}

.watchFull{
	cursor: pointer;
	position: absolute;
	color: #fff;
	top: 50%;
	left: 50%;
	z-index: 5;
	@include transform($center);
}
.videoWrapper .videoContainer:nth-child(odd):last-of-type{
	padding-bottom: 50% !important;
	width: 100% !important;
}
@media all and (max-width: 860px)  {
	.videoContainer{
		padding-bottom: 50%;
		width: 100%;
	}
}
/*** Video Page ***/

.db{
	display: block;
}

fieldset{
	border: none;
}
#SearchResults{ 
	padding: 0px;
	margin: 0px;
	display: block;
	float: left;
	width: 100%;
	font-size: 0.8em;
	li{
	padding: 30px 0px;
	border-bottom: 2px solid #eee;
	margin: 0px;
	display: block;
	float: left;
	width: 100%;
		list-style: none !important;
	}
}

/*** Search Form ***/
.SearchContainer:before {
    display: block;
    content: '';
    margin-top: -20px;
    right: 20px;
    top: 0px;
    position: absolute;
    border: 10px solid transparent;
    border-bottom: 10px solid #1f0b79;
}
.SearchContainer{
    display: none;
    position: fixed;
    top: 30px;
    @include border-radius(5px);
    background-color: #1f0b79;
    padding: 7px;
    right: 203px;
    z-index: 9999;
	@include transform(translateY(100%))

	#Search{
		display: block;
		float: left;
	}

    .middleColumn,
    button,
    #SearchForm_SearchForm_Search_Holder{
    	float: left;
    	display: block;
    }

    button{
	    background-color: rgb(141, 164, 68);
	    border: none !important;
	    color: #fff !important;
	    padding: 5px 10px;


	    i{
	    	color: #fff !important;
	    }
	    float: left;
    	display: block;
    }
    input[type=submit]{
        display: block;
	    border: none !important;
	    font-size: 1em;
	    text-transform: uppercase;
	    padding: 5px;
	    color: #fff !important;
	    font-style: italic;
	    @include border-radius(0px !important);
	    background-color: #17aae4;
    }
    input[type=text]{
	    border: 0px;
	    padding: 5px 10px;
	    @include border-radius(0px !important);
	    color: #5f5e6d;
	    float: left;
    	display: block;
    }
}
/*#SearchForm_SearchForm_Search{
	@include transition(all 400ms ease-in-out 0ms);
	background-color: rgba(200,200,200,.4);
	@include border-radius(50% 0% 0% 50%);
   	font-style: italic;
	position: absolute;
	padding: 0px 0px;
	z-index: 8888;
	height: 35px;
    right: 48px;
	color: #fff;
	border: 0px;
	width: 0px;
}

.activeSearch{
	border-radius: 0% !important;
	width: 150px !important;
 	padding: 0px 25px 0px 20px !important;
}

#SearchForm_SearchForm_action_results{
	color: #ddd;
	@include transition($t1);
	@include appearance(none);
    outline: 0!important;
    position: relative;
    z-index: 9980;
    font-family: WebSymbols;
    text-decoration: none!important;
    display: inline-block;
    text-align: center;
    cursor: pointer;
    border: none!important;
    margin-left: 0;
    font-size: 2em;
    background-color: transparent;
    &:hover{
    	color: #fff;
    }
}
*/
/*** Smooth Scrolling ***/

/*** Smooth Scrolling ***/
.smooth {
    top: 0;
    left: 0;
    right: 0;
    position: fixed;
    background-color: $mainColor;
    @include perspective(1000);
    @include transform-origin(0 0);
	@include box-sizing(border-box);
    @include backface-visibility(hidden);
    @include transform(translate3d(0px, 0px, 0px));
    @include transition(all .5s cubic-bezier(0.2, .84, .5, 1));
}

.wrapper{
	position: relative;
	float: left;
	width: 100%;
	background-color: $mainColor;
}

.SmoothScroll{
	 .parallax1 {
		@include perspective(1000);
	    @include transform-origin(0 0);
		@include box-sizing(border-box);
	    @include backface-visibility(hidden);
	    @include transform(translate3d(0px, 0px, 0px));
	    @include transition(all .5s cubic-bezier(0.2, .84, .5, 1));
	}
}

.StandardScroll{
	.smooth{
		 position: relative;
	}
}

/*** Loading Bar ***/
.pace {
  -webkit-pointer-events: none;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  z-index: 2000;
  position: fixed;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 5px;
  width: 200px;
  z-index: 9999;
  background: #fff;
  border: 1px solid #29d;
  overflow: hidden;
  display: none !important;
}

.pace .pace-progress {
	@include box-sizing(border-box);
	@include transform(translate3d(0, 0, 0));
	max-width: 200px;
	position: fixed;
	z-index: 2000;
	display: block;
	position: absolute;
	top: 0;
	right: 100%;
	height: 100%;
	width: 100%;
	background: #29d;
}

.pace.pace-inactive {
  display: none;
}

/*** Page Loading ***/

.pace-running{
	overflow: hidden;
}

/*** Page Loading Complete ***/
.pace-done{
	.Loading{
		height: 0%;
		opacity: 0;
	}
}

/*** HomePage ***/
.banner{
	position: relative;
	overflow: hidden;
	float: left;
	width: 100%;/*
	height: 100vh;*/
    height: 110vh;
	@include background-cover(url("../images/bg.jpg"));

	.darken{
		@include stretchCover(rgba(43,43,43, 0.5), absolute, 1)
	}

	.bg{
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0px;
		left: 0px;
		@include background-cover(url("../images/bg.jpg"));
	}
}

.SlideInner{
	background-color: #fff;
	padding: 5px;
	@include border-radius(10px);
	.slideImage{
		position: absolute;
		width: 100%;
		height: 100%;
		top: 50%;
		left: 50%;
		@include border-radius(7px 0px 0px 7px);
		@include transform($center);
		@include background-cover(url("../images/bg.jpg"));
	}
	.SlideTitle{
		color: #1f0b79;
	    margin-bottom: 30px;
	    font-size: 1.3em;
	}
	.SlideContent{}
	.SlideReadMore{
		a{
			background-color: #1f0b79;
		    display: inline-block;
		    padding: 4px 20px;
		    text-transform: uppercase;
		    color: #feec52;
		    font-size: 0.8em;
		    margin-top: 20px; 
		}
	}
	.slideContentSection{
		padding: 30px;
	}
}

#SliderControls{
	display: inline-block;
	vertical-align: middle;
	.next{
		position: relative;
		display: inline-block;
		margin-right: 5px;
		height: 30px;
		width: 30px;
		background-position: contain;
		background-position: center;
		background-repeat: no-repeat;
		background-image: url(../images/arrow2.png);
		a{
			position: absolute;
			top: 0px;
			left: 0px;
			width: 100%;
			height: 100%;
		}
	}
	.prev{
		position: relative;
		display: inline-block;
		margin-right: 5px;
		height: 30px;
		width: 30px;
		@include transform(rotate(-180deg));
		background-position: contain;
		background-position: center;
		background-repeat: no-repeat;
		background-image: url(../images/arrow2.png);
		a{
			position: absolute;
			top: 0px;
			left: 0px;
			width: 100%;
			height: 100%;
		}
	}
}
.underlineBtn{
	position: relative;
	display: inline-block;
	vertical-align: middle;
	padding-bottom: 10px;
	margin-left: 20px;
	color: #1f0b79 !important;
	img{
		position: absolute;
		bottom: 5px;
		width: 100%;
		height: 5px;
		left: 0px;
	}
}

.CircleContainer{
	padding-bottom: 100%;
	.front{
		position: absolute;
		top: 50%;
		left: 50%;
		width: 100%;
		height: 100%;
		@include transform($center rotateY(0deg));
		@include transition(all 1s);
		background-color: #2b86ef;
		color: #fff;
		*{color: #fff;}
		@include border-radius(50%);
		backface-visibility: hidden;
		-webkit-backface-visibility: hidden;
	}
	.back{
		position: absolute;
		top: 50%;
		left: 50%;
		width: 100%;
		height: 100%;
		@include transform($center rotateY(-180deg));
		@include transition(all 1s);
		background-color: #feed57;
		color: #150068;
		*{color: #150068;}
		@include border-radius(50%);
		backface-visibility: hidden;
		-webkit-backface-visibility: hidden;
	}
	&:hover{
		.front{
			@include transform($center rotateY(-180deg) !important);
		}
		.back{
			@include transform($center rotateY(0deg) !important);
		}
	}
	a{
		position: absolute;
		top: 0px;
		width: 100%;
		height: 100%;
		left: 0px;
	}
	.EventTitle{
		font-size: 1.6em;
	   	width: 70%;
	    top: 20%;
		position: absolute;
		left: 50%;
		@include transform(translateX(-50%));
	}
	.EventDate{
		font-size: 1.4em;
		position: absolute;
		bottom: 15%;
		left: 50%;
		width: 80%;
		@include transform(translateX(-50%));
		img{
			position: absolute;
			top: -30px;
			left: 50%;
			@include transform(translateX(-50%));
		}
	}
	.backCenter{
		position: absolute;
		top: 50%;
		left: 50%;
		@include transform($center);
		font-size: 1.4em;
		width: 90%;
		padding-bottom: 20px;
		img{
			position: absolute;
			bottom: 0px;
			left: 50%;
			@include transform(translateX(-50%));
		}
	}
}

#LatestEvents{
	.inner{
		width: 1200px;
	}
}

#home1{
	padding: 100px 0px 0px 0px;
	p{
		color: #fff;
		*{color: #fff;}
		a{color: #1f0b79 !important;}
	}
	strong{
		color: #feec52 !important;
	}
}

#home5{
	background-color: #fff;
	padding: 50px;
	border-bottom: 6px solid #e3e3e3;
	.LogoSliderInner{
		padding-bottom: 100%;
		img{
			position: absolute;
			top: 50%;
			left: 50%;
			max-width: 90%;
			@include transform($center);
		}
	}
	.prev,
	.next{
		position: absolute;
		top: 50%;
		background-size: contain;
		background-position: center;
		background-repeat: no-repeat;
		width: 30px;
		@include transform(translateY(-50%));
		height: 30px;
		a{
			position: absolute;
			top: 0px;
			left: 0px;
			width: 100%;
			height: 100%;
		}
	}
	.prev{
		left: 5px;
		background-image: url(../images/prev.png);
	}
	.next{
		background-image: url(../images/next.png);
		right: 5px;
	}
	.LogoSliderContainerOuter{
		padding-left: 40px;
		padding-right: 40px;
	}
}
.totop{
	cursor: pointer;
	color: #fff;
		margin-bottom: 15px;
	img{
		display: block;
		float: right;
	}
	span{
		padding-top: 20px;
		font-size: 1.2em;
		display: block;
		width: 100%;
		clear: both;
	}
	*{color: #fff;}
}
/*** Page ***/
.Page{
	.banner{
		height: 50vh;
	}
}
.dib{
	display: inline-block;
}
/*** Footer ***/
.quoteBox {
    position: absolute;
    top: 50%;
    left: 150px;
    @include transform(translate(0%, -50%));
    z-index: 2;
    color: #fff;
    font-size: 2em;
    display: block;
    opacity: 1;
    line-height: 1.2em;
    text-transform: uppercase;
    *{
    	color: #fff !important;
    }
}

.group2.homeImg{
	@include transform(scale(0.8));
}
.group2{
	opacity: 0;
}
#home1.ActiveIn{
	.group2{
		opacity: 1 !important;
	}
	.group2.homeImg{
		@include transform(scale(1) !important);
	}
}

.group3{
	opacity: 0;
}
#home3.ActiveIn{
	.group3{
		opacity: 1 !important;
	}
}

.group4{
	opacity: 0;
}
#home4.ActiveIn{
	.group4{
		opacity: 1 !important;
	}
}

.group5{
	opacity: 0;
}
#home5.ActiveIn{
	.group5{
		opacity: 1 !important;
	}
}
.group1{
	opacity: 0;
}
.SiteActiveIn{
	.group1{
		opacity: 1 !important;
	}
}

#StaffhiddenLinks,
#hiddenLinks{
	display: none;
}
#StaffLinksToggle,
#LinksToggle{
	cursor: pointer;
	position: relative;
	padding-right: 40px;
	margin-top: 10px;
	img{ 
		position: absolute;
		top: 50%;
		right: 10px;
		@include transition($t1);
		@include transform(translateY(-50%) rotate(0deg));
	}
}
 
#StaffLinksToggle.active, 
#LinksToggle.active{
	img{ 
		@include transform(translateY(-50%) rotate(-180deg) !important);
	}
}
/*
#StaffArea{
	position: absolute;
	top: 0px;
	right: 231px;
	z-index: 8002;
	padding: 30px 40px 10px 10px;
	@include border-radius(0px 0px 10px 10px);
	background-color: #1f0b79;

		color: #fff;
	*{
		font-size: 1em;
		color: #fff;
	}
	img{ 
		position: absolute;
		top: 67%;
		right: 10px;
		@include transition($t1);
		@include transform(translateY(-50%) rotate(-90deg));
	}
	overflow: hidden;
	a{
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
	}
}
*/
#SearchToggle{
    position: absolute;
    top: 0px;
    right: 167px;
    z-index: 8002;
    cursor: pointer;
    padding: 9px 10px 10px 10px;
	@include border-radius(0px 0px 10px 10px);
	background-color: #1f0b79;
	color: #fff;
	*{
		font-size: 1em;
		color: #fff;
	}
	
}

#StaffArea,
#UsefullLinks{
	background-color: #1f0b79;
	position: absolute;
	top: 0px;
	z-index: 9980;
	right: 363px;
	a{
		display: block;
		width: 100%;
		clear: both;
		font-size: 0.8em;
		@include transition($t1);
		border-bottom: 1px solid rgba(255,255,255, 0.1);
		&:hover{
			color: #fdfc33 !important;
		}
	}
	*{
		font-size: 1em;
		color: #fff;
	}
	padding: 20px 10px 10px 10px;
	@include border-radius(0px 0px 10px 10px);
}
#StaffArea{
	max-width: 134px;
	right: 226px;
}
#sideLinks{
	position: absolute;
	top: 50%;
	right: 0px;
	@include transform(translateY(-50%));
	a{
		font-size: 0.9em;
		min-height: 31px;
		display: block;
		clear: both;
		float: right;
		padding: 5px 80px 5px 20px;
		margin-bottom: 7px;
		@include border-radius(10px 0px 0px 10px);
		background-color: #000;
		.title{color: #fff !important;display: inline-block; 
			*{
				color: #fff;
			}
			span{display: block;
			width: 100%;
			clear: both;}
		}
		position: relative;
		@include transition($t1);
		&:hover{
			padding: 5px 90px 5px 20px;
		}
		img{
			position: absolute;
			top: 50%;
			right: 10px;
			max-height: 95%;
			@include transform(translatey(-50%));
		}
	}

	a:nth-child(even){
		background-color: #2f98f0;
	}

	a:nth-child(odd){
		background-color: #1f0b79;
	}
}

.smallFont{
	font-size: 0.65em;
	line-height: 1.4em;
}

.pageTop {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    display: block;
    max-height: 100px;
    @include transform(translateY(-97%));
}

.contentBorder{
	border-right: 1px solid #1f0b79;
}

.InnerPage{
	.topBar{
		opacity: 1 !important;
		@include transition(all 0ms !important);
	}
}


.NewsletterItem{
	display: block;

		width: 33.33%;
		float: left;
	padding: 10px;
	.NewsletterInner{
	background-color: #fff;
		display: block;
		position: relative;
		width: 100%;
		@include border-radius(3px);
		@include transition($t1);
		border: 5px solid #fff;
		@include box-shadow(0px 0px 10px 0px #9c9c9c);
		overflow: hidden;
		.newsletterThumb{
			float: left;
			clear: both;
			background-color: #eee;
			width: 100%;
			background-image: url(../images/newsletter.png);
			background-size: 60%;
    		background-repeat: no-repeat;
			background-position: center;
			padding-bottom: 110%;
		}
		a{
			position: absolute;
			top: 0px;
			left: 0px;
			width: 100%;
			height: 100%;
		}
	}
	&:hover{
		.NewsletterInner{
			border: 5px solid #303c68 !important;
		}
	}
	.newsletterTitle{
		padding: 10px;
		font-size: 0.8em;
		float: left;
	    width: 100%;
	    text-align: center;
	    clear: both;
	}
}

.Newsletter{
	width: 33.33%;
    padding-bottom: 33.33%;
    position: relative;
	min-width: 200px;
    min-height: 200px;
    display: inline-block;
    float: none;
}
.NewsLetterInner{
	top: 50%;
	left: 50%;
	width: 90%;
	height: 90%;
	border-radius: 3px;
	overflow: hidden;
	position: absolute; 
	border: 2px solid #d2d2d2;
    background-color: #f9f9f9;
	transition: all 0.35s;
	-o-transition: all 0.35s;
	-ms-transition: all 0.35s;
	-moz-transition: all 0.35s;
	-webkit-transition: all 0.35s;
	transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
	box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0); 
	-o-box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0); 
	-moz-box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0); 
	-webkit-box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0); 
}



.NewsLetterInner .imgContainer{
	display: inline-block;
	position: absolute;
	left: 50%;
	top: 40%;
	transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
}
.Newsletter a{
	position: absolute; 
	width: 100%;
	height: 100%;
	z-index: 200;
	left: 0px;
    top: 0px;
}
.Newsletter:hover .NewsLetterInner{
	border: 2px solid #d2d2d2;
    background-color: #f9f9f9;
	box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0.15); 
	-o-box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0.15); 
	-ms-box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0.15); 
	-moz-box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0.15); 
	-webkit-box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0.15); 
}

.Newsletter .downloadInfo{
	position: absolute;
	transform: translateX(-50%);
	-o-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	-moz-transform: translateX(-50%);
	-webkit-transform: translateX(-50%);
	left: 50%;
	color: #777;
	width: 98%;
	text-align: center;
	font-size: 1em;
	bottom: 10%;
	transition: all 0.35s;
	-o-transition: all 0.35s;
	-ms-transition: all 0.35s;
	-moz-transition: all 0.35s;
	-webkit-transition: all 0.35s;
}

.Newsletter:hover .downloadInfo{
	
    color: #2f98f0 !important;
}

.NewsletterTitle{
	position: relative;
	padding-bottom: 10px;
	margin-bottom: 30px;
	margin-top: 50px;
	text-align: center;
	font-size: 1.5em;
	color: #303c68 !important;
	*{
		font-weight: lighter;
		color: #303c68 !important;
	}
}
.archives{
	position: relative;
	display: block;
	float: left;
	width: 100%;
	clear: both;
	padding: 0px !important;
	margin: 0px 0px 30px 0px !important;

	.archive{
		position: relative;
		display: block;
		float: left;
		clear: both;
		color: #fff;
		width: 100%;
		margin: 5px 0px;
		cursor: pointer;
		@include transition($t1);
		img{
			position: absolute;
		    right: 10px;
		    top: 50%;
		    max-width: 17px;
			@include transform(translateY(-50%));
		}
		a{
			display: block;
			width: 100%;
			clear: both;
			float: left;
			padding: 8px 30px  8px 15px;
			top: 0px;
			left: 0px;
			width: 100%;
			z-index: 1;
			height: 100%;
		}
		*{
			color: #fff !important;
		}
		
		background-color: #303c68;
		@include border-radius(3px);
		 &:hover{
		    	background-color: #2f98f0 !important;
		    }
	}
}


.archive{
	*{
		color: #fff !important;
	}
}

.news-teaser{
	padding: 30px 0px;
	border-bottom: 2px solid #eee;
	display: block;
	float: left;
	width: 100%;

	h3{
    	font-family: 'BellotaBold';
  		color: #303c68;
  		font-size: 1.5em;
	}
	img{
		border: 6px solid #fdfc33;
		@include border-radius(50%);
	}
}

.moreNews{
	margin-top: 30px !important;
}



.noticesBG{
	position: fixed;
	display: none;
	top: 0px;
	left: 0px;
	z-index: 9999;
	height: 100%;
	width: 100%;
	background-color: rgba(0,0,0, 0.75);
}
.noticesContainer{
	position: fixed;
	z-index: 9999;
	top: 50%;
	display: none;
	left: 50%;
	@include transform($center);
	padding: 30px;
	background-color: #2f98f0;
	max-width: 100%;
	width: 500px;
}
.NoticeTitle{
	text-transform: uppercase;
	font-weight: bold;
	padding: 0px !important;
    border-bottom: none !important;
    font-size: 1.3em;
}
.readMoreLinkNotices{
    font-size: 0.8em;
    border: 2px solid #fff;
    padding: 3px 5px;
    margin-top: 10px;
    color: #fff !important;
    display: inline-block;
}
.fullWidth{
	position: relative;
	clear: both;
	width: 100%;
	float: left;
}
.closeNotices{
	position: absolute;
	top: 10px;
	right: 10px;
	padding-right: 20px;
	color: #fff;
	font-size: 0.8em;
	cursor: pointer;
	z-index: 9999; 

}
.closeNotices:after {
    content: "x";
    position: absolute;
    color: #fff;
    display: block;
    right: 5px;
    top: 49%;
    text-transform: uppercase;
    @include transform(translateY(-50%));
}
.noticesInner{
	max-height: 90vh;
    overflow-y: auto;
    padding: 10px;
	*{
		color: #fff;
	}
	.col-12{
		padding: 20px 0px;
		border-bottom: 2px solid rgba(255,255,255,0.6);
	}

}

.galleryImage{
	display: inline-block;
	width: 33.33%;
	min-width: 180px;
	min-height: 180px;
	position: relative;
	padding-bottom: 33.33%;

	.galleryImageInner{
		position: absolute;
		overflow: hidden;
		width: 95%;
		height: 95%;
		left: 50%;
		top: 50%;
		background-color: #2b2b2b;
		@include transform(translate(-50%,-50%));
		.gallerImageBg{
			@include transition($t1);
			position: absolute;
			width: 100%;
			height: 100%;
			background-size: cover;
			background-positon: center;
			left: 0px;
			top: 0px;
			opacity: 1;
		}
		.gallerImageText{
			opacity: 0;
			text-align: center;
			width: 95%;
			@include transition($t1);
			position: absolute;
			top: 50%;
			left: 50%;
			color: #fff !important;
			@include transform(translate(-50%,-50%) scale(0.5));
		}
	}
	&:hover{
		.galleryImageInner{
			.gallerImageBg{
				opacity: 0.2 !important;
			}
			.gallerImageText{
				@include transform(translate(-50%,-50%) scale(1));
				opacity: 1 !important;
			} 
		}
	}
	a{
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0px;
		left: 0px;
		z-index: 2;
	}
}

/*
|===============================================================
|	Gallery 3
|===============================================================
*/

#bx_slider {
	height: 500px;
	li {
		height: 100%;
		text-align: center;
		img {
			display:inline-block;
			height: 100%;
		}
	}
}
.innerSlideGal{
	padding-bottom: 100%;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	a{
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
	}
}

.GalNext,
.GalPrev{
	cursor: pointer;
	position: absolute;
	z-index: 200;
	top: 50%;
	margin-top: 8px;
	@include transform(translateY(-50%));
	i{
		color: #fff !important;
	}
}
.GalNext{
	left: 0px;
	padding: 20px;
	background-color: rgba(0,0,0,0.9);
}
.GalPrev{
	right: 0px;
	padding: 20px;
	background-color: rgba(0,0,0,0.9);
}

.bx_pages_frame {
	position: relative;
	#bx_pages {
		margin-top: 20px;
		li {
			background-position: center center;
			cursor: pointer;
			height: 200px;
			    opacity: 0.5;
			    @include transition(opacity 0.35s);
			    &:hover{
			   	 opacity: 1 !important;
			    }
		}
	}
	.controls {
		i {
			position: absolute;
			top: 50%;
			color: $mainColor2;
			font-size: 24px;
			margin-top: -12px;
			cursor: pointer;
			@include transition(all 0.5s);
			&:hover {
				opacity: 0.5;
			}
		}
		i.prev {
			left: 0;
		}
		i.next {
			right: 0;
		}
	}
}

/*
|===============================================================
|	Masonry
|===============================================================
*/

.grid {
	position: relative;
	top: 0;
	left: 0;
	width: 100%;
	height: 700px;
	a {
		font-size: 0;
		line-height: 0;
	}
}

.grid-item, .grid-sizer { 
	position: relative;
	width: 25%; 
	float: left;
	padding: 2px;
	cursor: pointer;
	@include transition(all 0.5s);
	img {
		width: 100%;
		height: auto;
	}
}

.grid-item.hoverState {
	opacity: 0.7;
}

.grid-item--width2 { width: 400px; }


.galleryImage{
	display: inline-block;
	width: 33.33%;
	min-width: 180px;
	min-height: 180px;
	position: relative;
	padding-bottom: 33.33%;

	.galleryImageInner{
		position: absolute;
		overflow: hidden;
		width: 95%;
		height: 95%;
		left: 50%;
		top: 50%;
		background-color: #2b2b2b;
		@include transform(translate(-50%,-50%));
		.gallerImageBg{
			@include transition($t1);
			position: absolute;
			width: 100%;
			height: 100%;
			background-size: cover;
			background-positon: center;
			left: 0px;
			top: 0px;
			opacity: 1;
		}
		.gallerImageText{
			opacity: 0;
			text-align: center;
			width: 95%;
			@include transition($t1);
			position: absolute;
			top: 50%;
			left: 50%;
			color: #fff !important;
			@include transform(translate(-50%,-50%) scale(0.5));
		}
	}
	&:hover{
		.galleryImageInner{
			.gallerImageBg{
				opacity: 0.2 !important;
			}
			.gallerImageText{
				@include transform(translate(-50%,-50%) scale(1));
				opacity: 1 !important;
			} 
		}
	}
	a{
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0px;
		left: 0px;
		z-index: 2;
	}
}

/*
|===============================================================
|	Uploads
|===============================================================
*/

.upload{
	position: relative;
	display: block;
	width: 100%;
	max-width: 500px;
	clear: both;
	float: left;
	padding: 10px 45px 12px 30px;
	border: 1px solid #2f98f0;
	margin: 5px 0px;
	background-color: transparent;
	@include transition($t1);
	.uploadTitle{
		font-size: 1em;
		font-weight: normal !important;
		color: #2f98f0 !important;
	    display: inline-block;
	}
	a{
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
		z-index: 3;
	}
	.fileIcon{


		position: absolute;
		right: 10px;
		top: 50%;
		@include transform(translateY(-50%));



	    background-color: #2f98f0;
	    display: inline-block;
	    float: right;
	    width: 25px;
	    height: 30px;
	    @include transition($t1);
	    span{
	    	@include transition($t1);
	    	color: #fff !important;
		    background-color: #102d40;
		    height: 13px;
		    width: 100%;
		    padding-top: 2px;
		    font-size: 7px;
		    text-transform: uppercase;
		    display: block;
		    text-align: center;
		    margin-top: 8px;
	    }
	}

	.uploadTitle{
		position: relative;
		z-index: 3;
		@include transition($t1);
	}

	&:before{
		content:'';
		z-index: 1;
		display: block;
		position: absolute;
		top: 0px;
		right: 0px;
		height: 100%;
		width: 60px;
		@include transition($t1);
		background-color: $themeColor;
	}

	&:hover{
		.uploadTitle{color: #fff !important;}
		.uploadTitle{
			/*color: #000 !important;*/
		}
		/*.fileIcon{
	    	background-color: #000 !important;
			span{
			    color: #000 !important;
				background-color: #009e7f;
		   }
		}*/
		&:before{
			width: 100%;
		}
	}
}

.uploadImage{
	z-index: 4;
	position: absolute;
	height: 80%;
	right: 20px;
	top: 50%;
	@include transform(translateY(-50%));
}



.staffProfile {
	.staffProfilePic {
		width:200px;
		height:200px;
		background-size: cover;
		background-position:center center;
		border-radius:20px;
	}
}
.HorizontalStack {
	.staffProfile {
		width:auto;
		margin:0 30px 30px 0;
		.profilePic {
			margin:0 !important;
		}
	}
}

/*
|===============================================================
|	Staff Page Templates
|===============================================================
*/

.StaffPage {
	.staffProfile {
		display:inline-block;
		width:100%;
		margin:30px;
		.profilePic {
			margin:0 30px 0 0;
			float:left;
			display:inline-block;
		}
		.bio {
			min-width:50%;

			p {
				display:block;
			}
		}
	}
}

.StaffPageStack {
	.staffProfile {
		text-align:center;
		margin-bottom:60px;
		.bio {
			p {
				text-align:justify;
				width:100%;
				max-width:800px;
				margin:0 auto 20px auto;
			}
		}
	}
}

.StaffPageNoPic {
	.staffProfile {
		text-align:center;
		margin-bottom:60px;
		.bio {
			p {
				text-align:justify;
				width:100%;
				max-width:800px;
				margin:0 auto 20px auto;
			}
		}
	}
}

.bio{
	h4{
		font-weight: normal;
    	font-size: 1.2em;
	}

	h3{
		font-size: 2em;
		font-family: 'BellotaBold';
   		color: #303c68;
	}
}

 .HorizontalStack .staffProfile {
    width: auto;
    margin: 0 30px 30px 0;
}

.VerticalStack{
	    margin: 0px ;
	  
	    .staffProfile {
	    	margin: 0px ;
			padding: 30px 0px;
			border-bottom: 2px solid #eee;
		}
}
.NameDepOnly{
	    margin: 0px ;
	  
	    .staffProfile {
	    	margin: 0px ;
			padding: 30px 0px;
			border-bottom: 2px solid #eee;
		}
}
.HorizontalStack{
	.profilePic{
		padding-bottom: 20px;
	}
}

.profilePic{
	padding-bottom: 20px;
    padding-top: 13px;
}
/*** Navigation ***/

.fancybox-overlay {
    z-index: 9999;
}

.vacancy{
	padding: 30px 0px;
	border-bottom: 2px solid #eee;
	display: block;
	float: left;
	width: 100%;
}
#SearchResults h2{
	font-size: 1.5em;
	*{font-size: 1em !important;}
}
.heading-2{
	font-size: 1.5em;
	color: #303c68;
	*{font-size: 1em;color: #303c68;}
}

.CalendarPage,
.CalendarEvent{
	.calendar-widget-table .next {
    	right: 24px;
	}
	.calendar-widget-table .prev {
	    right: 65px;
	}
}


#content{
	input[type=text],
	input[type=email],
	input[type=password],
	textarea{
	    width: 100%;
	    padding: 10px 20px;
	    background-color: #eee;
	    border: none !important;
	    margin-bottom: 10px;
	    margin-top: 5px;
	    width: 100%;
	 	max-width: 400px;
    	font-weight: lighter;
	}

	label{
		font-weight: lighter;
	}

	input[type=submit]{
			padding: 10px 20px !important;
		    display: block;
		    width: 100%;
		    border: none !important;
		    color: #fff !important;
	    	max-width: 400px;
		    font-weight: lighter;
		    margin-top: 10px;
		    @include transition($t1);
		    @include border-radius(3px);
		    background-color: #303c68;
			max-width: none !important;
		    width: auto !important;
		    display: inline-block;

			&:hover{
		    	background-color: #2f98f0 !important;
		    }
	}
	#ForgotPassword{
		margin-top: 20px;
		a{
			font-weight: lighter;
			color: #1d1e3c;
		}
	}
}
.url,
.readMoreLink,
.button{
			padding: 10px 20px !important;
		    display: inline-block;
		    text-align: center;
		    color: #fff !important;
		    font-size: 0.7em !important;
		    cursor: pointer;
		    *{
		    	font-size: 1em;
		    	color: #fff !important;
		    }
		    border: none !important;
		    color: #fff !important;
		    font-weight: lighter;
		    margin-top: 10px;
		    @include transition($t1);
		    @include border-radius(3px);
		   	background-color: #303c68;
			&:hover{
		    	background-color: #2f98f0 !important;
		    }
	}

#gmap_canvas{
	height: 500px;
}

.dib{
	display: inline-block;
}

.ContactForm{
	#content{
		input[type=submit],
		input[type=text],
		input[type=email],
		input[type=password],
		textarea{
			max-width: 100% !important;
		}
	}
}


.upload{
	/*padding: 10px;
	border-bottom: 2px solid #eee;*/
	*{
		vertical-align: middle;
		font-weight: lighter !important;
	}
}


.contentPage{
	padding-right: 20% !important;
}

.url:hover{
    background-color: #2579bf !important;
}

footer{
	overflow: hidden;
	padding: 40px 100px 40px 50px;
	.FooterLogo{
		/*width: 25%;*/
		display: inline-block;
		vertical-align: middle;
	}
	.FooterText{
		/*width: 75%;*/
		padding-left: 20px;
		display: inline-block;
		vertical-align: middle;
	}
	.footerLogoText{
		.part1{
			color: #fdfc33;
			margin-top: 10px;
    		font-size: 1.7em;
    		font-family: 'BellotaBold';
		}
		.part2{
			color: #1f0b79;
   			font-size: 1.2em;
   			margin-bottom: 20px;
		}
	}
	.FooterAddressText{
			color: #1f0b79;
		*{
			color: #1f0b79;
		}
	}
	.divider{
		color: #fdfc33 !important;
		display: inline-block;
		padding: 0px 10px;
	}
	.fse{
		font-size: 0.9em;
		color: #fff;
		*{
			color: #fff;
			font-size: 1em;
		}
	}
	.footerOverlay{
		position: absolute;
		top: 50%;
		right: 0px;
		max-height: 90%;
		@include transform(translateY(-50%));
  		animation: floatingTwo 5s infinite alternate;
  		-webkit-animation: floatingTwo 5s infinite alternate;
	}
}

.eventsListWrapper{    
	max-width: 800px;
    min-width: 0px;
}
.eventsListItem{
	background-color: #eee;
	margin-bottom: 20px;
	display: flex;
}
.EventsListImage .imageTile{
	position: absolute;
    height: 100%;
    padding-bottom: 0px;
    width: 100%;
    left: 0px;
    top: 0px;
	background-size: cover;
	background-position: center;
	background-image: url(../images/bg.jpg);
}
.EventsListContent{
	padding: 30px;
}
.EventsTitle{
	text-transform: uppercase;
	color: #1F0B79;
	font-size: 1.5em;
	margin-bottom: 20px;
}
.EventsContent{
	
	margin-bottom: 20px;
}


.prevList,
.nextList{
	position: relative;
	color: #fdfc33;
	background-color: #1F0B79;
	width: 25px;
	height: 25px;
	display: inline-block;
	margin-right: 5px;
	text-align: center;
	@include border-radius(50%);
	i{
	position: absolute;
		top: 50%;
		left: 50%;
		@include transform($center);
		color: #fdfc33;
	}
	display: inline-block;
	vertical-align: middle;
}

.prevList{
    i{top: 43%;}
}

.pageNumber{
	color: #1F0B79 !important;
}
.EventsView a{
display: inline-block;
vertical-align: middle;
	margin-top: 30px;
	display: inline-block;
	text-transform: uppercase;
	color: #fff !important;
	background-color: #2f98f0;
	padding: 10px 20px;
	font-size: 0.8em !important;
}



.contentPage.col-12{
	padding-right: 20px !important;
}

.footerLabel{
	font-weight: bold !important;
}

.RepositoryUpload{
	display: inline-block;
	padding: 10px;
	width: 50%;
}
.RepositoryUploadInner{
		background-color: #eee;
		position: relative;
	    padding: 25px 20px;
	    color: #1F0B79;
	    text-transform: uppercase;

	img{
		position: absolute;
		top: 50%;
		right: 10px;
		max-height: 90%;
		@include transform(translateY(-50%));
	}
	a{
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
	}
}

.ScrollNav{
	.topBar{
    	background-color: rgba(47,152,240,1) !important;
	}
}

.fixedImage{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	background-position: center;
	background-size: cover;
	background-image: url(../images/img8.jpg);
}

.staffProfile {
    width: auto;
    float: left;
}



table{
	max-width: 100% !important;
}
form{ 
	max-width: 600px;
}

.contentPage table{
	border-collapse: collapse;
    border: 1px solid #c7c7c7;
    }
.contentPage table th,
.contentPage table td{
	padding: 10px 5px !important;
	border-spacing: 0;
    border-collapse: collapse;
    border: 1px solid #c7c7c7;
}

.profilePicInner{
	padding-bottom: 75% !important;
	background-size: cover;
	background-position: center;
	min-width: 0px; 
}

.HorizontalStack{
text-align: center;
	.staffProfile{
	display: inline-block;
	float: none;
	min-width: 211px;
	padding: 10px;
	margin: 0px !important;
		width: 25%;
	}
}

.VerticalStack{
	.profilePic{
	max-width: 211px;
    min-width: 0px;
	}
}


.caption{
    font-size: 0.8em;
    text-align: center;
    margin-top: -3px;
    background-color: #f5f5f5;
    border: 1px solid #c7c7c7;
    padding: 5px 10px;
}

footer{
	background-color: #2f98f0 !important;
}

.fse{
	*{
		color: #fff !important;
	}
}


.HorizontalStack .staffProfile{
	width: 50%;
	.profilePicInner {
	   
	    width: 200px;
	    height: 150px;
	    padding-bottom: 0px !important;
	    margin: 0 auto;
	    float: none;
	}
}

.flexs.col-12.clear{
	max-width: 600px !important;	
	width: 100%;
	margin: 0 auto;
	float: none;
	min-width: 0px !important;	
}

#tickerContainer{
height: 52px;
max-height: 52px;
		line-height: 18px !important;
		font-size: 18px !important;
	*{
		line-height: 18px !important;
		font-size: 18px !important;
	}
}


table.calendar-widget-table{
	border-collapse: collapse;
    border: none !important;
    }
table.calendar-widget-table th,
table.calendar-widget-table td{
	padding: 20px 0px !important;
	border-spacing: 0;
    border-collapse: collapse;
    border: none !important;
}
.typography a.show-month{
    font-size: 1.8em !important;
    color: #5a5962 !important;
}
